@import url(https://fonts.googleapis.com/css?family=Comfortaa);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  /* text-align: center; */
  background-color: whitesmoke;
}

.tracker {
  background-color: teal;
  padding: 15px;
  height: 95vh;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 100px;
}

.App-header {
  text-align: center;
  background-color: #222;
  height: 175px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
  font-family: Comfortaa;
}

.App-intro {
  font-size: large;
}

body {
  background: #f1f1f1;
}

div#crypto-container {
  background: white;
  width: 50%;
  margin: 7px auto 4px auto;
  padding: 1em;
  box-shadow: 1px 1px 0 lightgrey;
}

span.left {
  font-weight: bold;
}

span.right {
  float: right;
  text-align: left;
  margin: 0px 0px 0px 20px;
}

span.left#title {
  font-weight: bold;
  color: red;
}

span.right#title {
  font-weight: bold;
  color: red;
  float: right;
  text-align: left;
}

